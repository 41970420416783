import React from "react";
import { FormGroup } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import qs from "querystringify";
import ReactGA from "react-ga";

import {
  renderInputField,
  required,
  email,
  password,
} from "utils/form-helpers";
import Card from "dashboard/components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Auth from "utils/auth";
import _ from "lodash";
import { history as historyPush } from "../configureStore";
let LoginForm = (props) => {
  const { handleSubmit, isLoading, queryString } = props;
  let brandLogo = "";
  if (_.has(queryString, "brandId")) {
    let { brandId } = queryString;
    brandLogo = (
      <div className="brand-logo text-center">
        <img
          className="logo"
          src={`//cdn.optcentral.com/optportal/logos/${brandId}/logo_bnd_${brandId}_1.gif`}
          alt="brand"
        />
      </div>
    );
  } else {
    brandLogo = "";
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card
        textCenter
        // title={
        //   <div>
        //     {brandLogo}
        //     <Link
        //       onClick={(e) => (window.location.href = window.location.origin)}
        //       to="/"
        //     >
        //       {/* <img
        //         className="logo img-responsive"
        //         src={`${process.env.PUBLIC_URL}/siteNalysis_Logo.png`}
        //         alt="SiteNalysis"
        //         style={{
        //           marginTop: 10,
        //           marginBottom: 10,
        //           marginLeft: "auto",
        //           marginRight: "auto",
        //           maxWidth: 280,
        //         }}
        //       /> */}
        //       PRICE SCRAPER
        //     </Link>
        //     {/* <p>powered by OPT</p> */}
        //   </div>
        // }
        content={
          <div>
            <FormGroup>
              <Field
                name="email"
                fieldClass="form-control"
                component={renderInputField}
                type="email"
                validate={[required, email]}
                placeholder="Username"
              />
            </FormGroup>
            <FormGroup>
              <Field
                name="password"
                fieldClass="form-control"
                component={renderInputField}
                validate={[required, password]}
                type="password"
                placeholder="Password"
              />
            </FormGroup>
          </div>
        }
        legend={
          <div className="text-center">
            <Button
              className={isLoading ? "btn-wd btn disabled" : "btn-wd btn"}
              bsStyle="info"
              type="submit"
            >
              {isLoading ? "Please wait..." : "Login"}
            </Button>
          </div>
        }
        ftTextCenter
      />
    </form>
  );
};

LoginForm = reduxForm({
  form: "login",
})(LoginForm);

class LoginPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeMessageAlert = this.closeMessageAlert.bind(this);
  }

  componentDidMount() {
    let { location } = this.props;
    let search = qs.parse(location.search);
    if (!search.session) {
      const user = Auth.getUser();
      if (_.isEmpty(user)) {
        return false;
      }
      console.log("user", user);
      console.log("Auth.isAdmin()", Auth.isAdmin());
      if (Auth.isAdmin()) {
        // defaultRoute = '/admin';
        historyPush.push("/admin/confirm");
      } else {
        ReactGA.ga("set", "userId", user.email);
        ReactGA.ga("set", "dimension1", user.brand.slug);
        // Setting the userId doesn't send data to Google Analytics.
        // You must also use a pageview or event to send the data.
        ReactGA.event({
          category: "authentication",
          action: "logged in",
          label: user.email,
        });
        // defaultRoute = '/dashboard/summary';
        historyPush.push("/dashboard/summary");
      }
      return;
    }
    let message = "";
    let closeBtn = (
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={this.closeMessageAlert}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    );
    if (search.session === "logout") {
      message = (
        <div className="alert alert-info alert-dismissible">
          <strong>You have been logged out.</strong>
          {closeBtn}
        </div>
      );
    } else if (search.session === "expired") {
      message = (
        <div className="alert alert-danger alert-dismissible">
          <strong>Error: Session expired, please login again!</strong>
          {closeBtn}
        </div>
      );
    }
    this.setState(
      {
        message,
      },
      () => {
        Auth.removeAuth();
      }
    );
  }

  componentWillReceiveProps() {
    let { history } = this.props;
    let search = qs.parse(history.location.search);
    if (!_.isEmpty(search) && search.login === "failed") {
      this.setState({
        isLoading: false,
      });
    }
  }
  closeMessageAlert() {
    this.setState({
      message: "",
    });
  }
  handleSubmit(values) {
    this.setState({
      isLoading: true,
    });
    // After sucessful login, set session & redirect
    Auth.login(values);
    console.log("vals", values);
  }

  render() {
    const { isLoading, message } = this.state;
    let queryString = qs.parse(this.props.location.search);
    let loader = "";
    if (isLoading) {
      loader = (
        <i className=" fa fa-circle-o-notch fa-spin fa-fw login-loader" />
      );
    }
    return (
      <div className="login-page">
        {message && <div className="message-alert">{message}</div>}
        <div className="login-form">
          <LoginForm
            isLoading={isLoading}
            onSubmit={this.handleSubmit}
            queryString={queryString}
          />
          {loader}
        </div>
      </div>
    );
  }
}
export default withRouter(LoginPage);
