import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Sidebar from 'dashboard/components/Sidebar/Sidebar.jsx';
import Header from 'dashboard/components/Header/Header.jsx';
import DashFooter from 'dashboard/components/Footer/DashFooter.jsx';
import RetailerView from 'dashboard/RetailerView.jsx';
import RetailerProduct from 'dashboard/RetailerProduct.jsx';

import dashRoutes from 'routes/dashboard.jsx';
import Auth from 'utils/auth';
import store from 'store';
import _ from 'lodash';
// import ErrorBoundary from '../ErrorBoundary';

class Dash extends React.PureComponent {
  componentWillMount() {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
    // If User is not logged in then redirect to login page.
    let user = Auth.getUser();
    if (_.isEmpty(user)) {
      store.set('omni_deeplink', {
        path: this.props.location.pathname,
        qs: this.props.location.search
      });
      this.props.history.push('/login');
    }
  }

  getDashRoutes() {
    return dashRoutes.map((prop, key) => {
      if (prop.collapse) {
        return prop.views.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        });
      } else {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        else
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
      }
    });
  }

  getBrandThemeStyles() {
    // Theming App
    // Brand Specific style
    let user = Auth.getUser();
    let theme = {
      bgColor: '#333131',
      textColor: '#fff',
      hoverColor: '#ffffff3b',
      summaryPageSectionColor: '#333131'
    };
    // merge default theme
    if (_.isEmpty(user)) {
      return this.props.history.push('/login');
    }

    let brandColors = user.brand ? user.brand.colors : {};

    theme = Object.assign(theme, brandColors);

    return `
      #nprogress .bar {
        background: ${theme.textColor};
      }
      #nprogress .peg {
        box-shadow: 0 0 10px ${theme.textColor}, 0 0 5px ${theme.textColor};
      }
      #nprogress .spinner-icon {
        border-top-color: ${theme.textColor};
        border-left-color: ${theme.textColor};
      }
      #root .main-panel .navbar {
        background: ${theme.bgColor};
        color: ${theme.navColor};
      }

      #root .fab-icon {
        background: ${theme.bgColor} !important;
        color: ${theme.textColor};
        border: 1px solid ${theme.textColor};
      }
      #root .main-panel .navbar .btn-danger,
      #root .main-panel .navbar-brand {
        color: ${theme.textColor};
        border-color: ${theme.textColor};
      }
      #root .sidebar {
        background: ${theme.bgColor};
      }
      #root .sidebar:after,
      #root .sidebar:before {
        background: ${theme.bgColor};
      }
      #root .sidebar .nav li a,
      #root .sidebar .nav li p {
        color: ${theme.textColor};
      }
      #root .sidebar .nav li:hover a,
      #root .sidebar .nav li:active a {
        background: ${theme.hoverColor};
        color: ${theme.textColor};
      }
      #root .sidebar .nav li.active > a {
        background: ${theme.hoverColor};
        color: ${theme.textColor};
      }
      #root .btn.close-btn,
      #root .btn.close-btn:hover,
      #root .btn.close-btn:focus,
      #root .btn.close-btn:active,
      #root .btn.close-btn.active,
      #root .open > .btn.close-btn.dropdown-toggle {
        color: ${theme.textColor};
      }
      #root .sidebar .logo {
        color: ${theme.textColor};
      }
      #root .sidebar .logo img::before {
        background: ${theme.textColor};
      }
      #root .sidebar .logo:before,
      #root .bootstrap-navbar .logo:before {
        background: ${theme.textColor};
      }
      #root .summary-view-v2 .image-info-inner {
        background-color: ${theme.summaryPageSectionColor};
      }
    `;
  }

  render() {
    let content = '';
    // let pathName =
    //   this.props.location.pathname.indexOf("dashboard/summary") > -1 &&
    //   "brandSplash";

    let user = Auth.getUser();
    let isAdmin = Auth.isAdmin();

    if (!_.isEmpty(user) && isAdmin) {
      this.props.history.push('/login');
    }

    content = (
      <>
        <Switch>
          <Route
            exact
            path="/dashboard/retailer/:rId/product/:pId/sku/:brandSKU"
            component={RetailerProduct}
          />
          <Route
            exact
            path="/dashboard/retailer/:rId/product/:pId"
            component={RetailerProduct}
          />
          <Route
            exact
            path="/dashboard/retailer/:rId/period/:mId"
            component={RetailerView}
          />
          <Route
            exact
            path="/dashboard/retailer/:rId"
            component={RetailerView}
          />

          {this.getDashRoutes()}
        </Switch>
      </>
    );

    return (
      <div className="wrapper">
        <style
          className="App-Theme-style"
          dangerouslySetInnerHTML={{ __html: this.getBrandThemeStyles() }}
        />
        <Sidebar {...this.props} />
        <div
          className={
            'main-panel ' +
            (this.props.location.pathname === '/maps/full-screen-maps'
              ? ' main-panel-maps'
              : '')
          }
          ref="mainPanel"
        >
          <Header {...this.props} />
          <div className="content-wrapper">{content}</div>
          <DashFooter {...this.props} />
          {/* <button className="fab-icon">test</button> */}
        </div>
      </div>
    );
  }
}
export default Dash;
