import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  SelectionState,
  Modifier,
  convertFromHTML,
} from "draft-js";
import forEach from "lodash/forEach";
import draftToHtml from "draftjs-to-html";
import isEmpty from "lodash/isEmpty";
import MessageSelect from "../draft-js/MessageSelect";
import FileUpload from "../../components/FileUpload";

import { Row, Col, Form, FormGroup, ControlLabel } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { omni_axios_instance } from "utils/axios";
import { APP_VERSION } from "utils/constants";
import Auth from "utils/auth";
import SuccessModal from "../SuccessModal";

class RetailerContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessModal: false,
      selectedToValue: [],
      selectedCcValue: [],
      selectedBccValue: [],
      errors: [],
      selectedFiles: [],
      nameTo: "",
      variablesInfo: {},
      editorState: this.props.links
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(this.props.links))
          )
        : EditorState.createEmpty(),
      isCanned: false,
      emailOptions: [],
      subject: "",
    };
    this.saveSettings = this.saveSettings.bind(this);
    this.uploadedFiles = this.uploadedFiles.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.handleChangeCc = this.handleChangeCc.bind(this);
    this.handleChangeBcc = this.handleChangeBcc.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isCannedOrNot = this.isCannedOrNot.bind(this);
    this.insertText = this.insertText.bind(this);
  }
  componentWillMount() {
    let user = Auth.getUser();
    let emailOptions = [];
    let userEmailsArr = [];
    let brandEmailsArr = [];
    if (!isEmpty(user)) {
      if (!isEmpty(user.user_email_bcc)) {
        var userEmails = user.user_email_bcc;
        userEmailsArr = userEmails.split(",");
      }
      // if(user.roles.length) {
      let userBrand = user.brand;
      if (!isEmpty(userBrand.company_email_bcc)) {
        var brandEmailsStr = userBrand.company_email_bcc;
        brandEmailsArr = brandEmailsStr.split(",");
      }
      // }
    }
    let emails = [...userEmailsArr, ...brandEmailsArr];
    forEach(emails, function (email) {
      emailOptions.push({ value: email, label: email });
    });
    this.setState({
      emailOptions,
    });
    if (this.props.links) {
      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(this.props.links))
        ),
      });
    }
  }
  componentDidMount() {
    let { rId } = this.props;
    const formData = new FormData();
    formData.append("retailer_brand_scraping", rId);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    omni_axios_instance
      .post(`/getContactInfoForEmail/`, formData, config)
      .then((res) => {
        this.setState({
          variablesInfo: res.data.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    // if (this.props.links) {
    //   // this.setState({
    //   //   editorState: this.insertText(this.props.links, this.state.editorState),
    //   // });
    //   // this.setState({
    //   //   editorState: EditorState.createWithContent(
    //   //     ContentState.createFromBlockArray(convertFromHTML(this.props.links))
    //   //   ),
    //   // });
    // }
    if (this.props.subject) {
      this.setState({ subject: this.props.subject });
    } else if (this.props.multiple || this.props.flagging) {
      this.setState({
        subject: `Issues found on the ${this.props.retailer.name} website`,
      });
    } else {
      this.setState({
        subject: `Issue found on the ${this.props.retailer.name} website`,
      });
    }
  }

  // insertText() {
  //   let { editorState } = this.state;
  //   let { links } = this.props;
  //   // get current editor state
  //   const currentContent = editorState.getCurrentContent();

  //   // create new selection state where focus is at the end
  //   const blockMap = currentContent.getBlockMap();
  //   const key = blockMap.last().getKey();
  //   const length = blockMap.last().getLength();
  //   const selection = new SelectionState({
  //     anchorKey: key,
  //     anchorOffset: length,
  //     focusKey: key,
  //     focusOffset: length,
  //   });

  //   //insert text at the selection created above
  //   const textWithInsert = Modifier.insertText(
  //     currentContent,
  //     selection,
  //     links,
  //     null
  //   );
  //   const editorWithInsert = EditorState.push(
  //     editorState,
  //     textWithInsert,
  //     "insert-characters"
  //   );

  //   //also focuses cursor at the end of the editor
  //   const newEditorState = EditorState.moveSelectionToEnd(
  //     editorWithInsert,
  //     textWithInsert.getSelectionAfter()
  //   );
  //   // setEditorState(newEditorState);
  //   this.setState({
  //     editorState: newEditorState,
  //   });
  // }

  insertText(text, editorState) {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      // "insert-characters",
      // "change-block-data",
      // "change-block-type",
      "insert-fragment"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  }

  handleChangeTo(value) {
    let to = [];
    to.push(value);
    let nameVal = "";
    if (to.length) {
      nameVal = to.map((o) => o.name).join(",");
    }
    this.setState({
      nameTo: nameVal,
      selectedToValue: to,
    });
  }

  handleChange(e) {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
    });
  }

  handleChangeCc(newValue, actionMeta) {
    this.setState({
      selectedCcValue: newValue,
    });
  }

  handleChangeBcc(newValue, actionMeta) {
    let { selectedBccValue } = this.state;
    console.log("newValue ==", newValue);
    // selectedBccValue.push(actionMeta.option)
    this.setState({
      selectedBccValue: newValue,
    });
  }

  validate() {
    let { selectedToValue, editorState } = this.state;
    let hasMessage = editorState.getCurrentContent().hasText();

    let tErrors = [];
    if (!selectedToValue.length) {
      tErrors.push("Please enter first target email address.");
    }
    if (!hasMessage) {
      tErrors.push("Please enter message.");
    }
    return tErrors;
  }

  uploadedFiles(files) {
    this.setState({
      selectedFiles: files,
    });
  }

  saveSettings(e) {
    e.preventDefault();
    console.log("selectedBccValue ===", selectedBccValue);
    // First validate form and then submit
    let tErrors = this.validate();
    if (!isEmpty(tErrors)) {
      this.setState({
        errors: tErrors,
      });
      return;
    }

    // get current logged in user
    let user = Auth.getUser();
    let {
      selectedToValue,
      selectedCcValue,
      selectedBccValue,
      editorState,
      selectedFiles,
      isCanned,
      emailOptions,
    } = this.state;

    let to = "";
    let nameTo = "";
    let message = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    let newMessage = message.replace(/<\/?p[^>]*>/g, "");

    if (selectedToValue.length) {
      to = selectedToValue.map((o) => o.email).join(",");
      nameTo = selectedToValue.map((o) => o.name).join(",");
    }
    let cc = "";
    if (selectedCcValue.length) {
      let { email, name } = user.brand.contact;
      selectedCcValue = [].concat(
        [
          {
            value: email,
            label: name,
          },
        ],
        selectedCcValue
      );
      cc = selectedCcValue.map((o) => o.value).join(",");
    }
    let bcc = "";
    if (!selectedBccValue.length) {
      bcc = emailOptions.map((o) => o.value).join(",");
    }
    if (selectedBccValue.length) {
      bcc = selectedBccValue.map((o) => o.value).join(",");
    }
    let products = [];
    let {
      source,
      multiple,
      products: productList,
      rId,
      pId,
      flagging,
      retailer,
    } = this.props;

    if (multiple) {
      forEach(productList.items, function (item) {
        products.push(item._id);
      });
    } else if (flagging) {
      products.push(pId);
    } else {
      products.push(pId);
    }

    // let subject = "";
    if (this.props.subject) {
      // subject = this.props.subject;
      this.setState({ subject: this.props.subject });
    } else if (multiple || flagging) {
      // subject = `Issues found on the ${retailer.name} website`;
      this.setState({
        subject: `Issues found on the ${retailer.name} website`,
      });
    } else {
      this.setState({ subject: `Issue found on the ${retailer.name} website` });
    }

    const formData = new FormData();
    formData.append("subject", this.state.subject);
    formData.append("name", nameTo);
    formData.append("to", to);
    formData.append("cc", cc);
    formData.append("bcc", bcc);
    formData.append("message", newMessage);
    formData.append("source", source);
    formData.append("isCanned", isCanned);
    if (source !== "brand") {
      formData.append("products", products);
    }
    formData.append("retailer_brand_scraping", rId);
    formData.append("retailer", retailer.name);
    for (var i = 0; i < selectedFiles.length; i++) {
      formData.append("file", selectedFiles[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    omni_axios_instance
      .post(`/contact_retailer?version=${APP_VERSION}`, formData, config)
      .then((response) => {
        toastr.success("SUCCESS", "Email Sent Successfully.");
        this.setState({
          errors: [],
          showModal: false,
          showSuccessModal: true,
        });
      })
      .catch((error) => {
        this.setState({
          errors: [],
          showModal: false,
        });
        // https://github.com/axios/axios#handling-errors
      });
  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };

  isCannedOrNot(val) {
    this.setState({
      isCanned: val,
    });
  }

  render() {
    console.log("state :", this.state);
    console.log("props :", this.props);
    const { multiple, flagging, contactInfo, retailer } = this.props;
    let { retailer_contacts } = contactInfo;
    let user = Auth.getUser();
    // let subject = "";
    // if (this.props.subject) {
    //   subject = this.props.subject;
    // } else if (multiple || flagging) {
    //   subject = `Issues found on the ${retailer.name} website`;
    // } else {
    //   subject = `Issue found on the ${retailer.name} website`;
    // }
    let {
      showSuccessModal,
      selectedToValue,
      selectedCcValue,
      selectedBccValue,
      errors,
      editorState,
      variablesInfo,
      emailOptions,
      subject,
    } = this.state;
    return (
      <>
        <Row className="contact-page-container">
          <Col xs={12}>
            <Form
              horizontal
              className="Retailer-ContactForm"
              onSubmit={this.saveSettings}
            >
              {errors.length > 0 && (
                <header className="Retailer-ContactForm__errors">
                  {errors.map((e, index) => (
                    <p className="error" key={index}>
                      {e}
                    </p>
                  ))}
                </header>
              )}
              <FormGroup>
                {/* <div className="contact-subject">
                  <strong> SUBJECT: </strong>
                  {/* <span className="subject">{subject}</span> 
                  <input
                    className="form-control subject"
                    value={subject}
                    onChange={(e) => this.setState({ subject: e.target.value })}
                  />
                </div> */}
                <Col componentClass={ControlLabel} sm={2} className="p-0">
                  <strong> SUBJECT: </strong>
                </Col>
                <Col sm={10}>
                  <input
                    className="form-control"
                    value={subject}
                    onChange={(e) => this.setState({ subject: e.target.value })}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col componentClass={ControlLabel} sm={2}>
                  To:
                </Col>
                <Col sm={10}>
                  <Select
                    simpleValue
                    value={selectedToValue}
                    isClearable={false}
                    placeholder=""
                    onChange={this.handleChangeTo}
                    options={retailer_contacts}
                    noOptionsMessage={() =>
                      `To add one or more emails to the "to" field, please add them to your address book on the "CONTACT" tab found within a Retailer's profile.`
                    }
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    getOptionValue={(option) => option.email}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col componentClass={ControlLabel} sm={2}>
                  CC:
                </Col>
                <Col sm={10}>
                  <CreatableSelect
                    isMulti
                    simpleValue
                    value={selectedCcValue}
                    isClearable={false}
                    noOptionsMessage={() =>
                      `To add email address, please type in above box.`
                    }
                    placeholder=""
                    options={false}
                    onChange={this.handleChangeCc}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col componentClass={ControlLabel} className="text-left" sm={2}>
                  <strong>BCC</strong>
                </Col>
                <Col sm={10}>
                  <CreatableSelect
                    isMulti
                    onChange={this.handleChangeBcc}
                    options={emailOptions}
                    defaultValue={emailOptions}
                    noOptionsMessage={() =>
                      `To add email address, please type in above box.`
                    }
                  />
                </Col>
              </FormGroup>

              <FormGroup className="Retailer-ContactForm__message">
                <Col className="text-left" sm={12}>
                  <label className="Retailer-ContactForm__message--label">
                    message content
                  </label>
                </Col>
                <Col sm={12}>
                  <Editor
                    wrapperClassName="MessagesEditor-wrapper"
                    editorClassName="MessagesEditor-editor"
                    toolbarClassName="MessagesEditor-toolbar"
                    placeholder="Message"
                    toolbar={{
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      options: [
                        "inline",
                        "blockType",
                        "list",
                        "textAlign",
                        "link",
                        "remove",
                      ],
                    }}
                    editorState={editorState}
                    toolbarCustomButtons={[
                      <MessageSelect
                        variablesInfo={variablesInfo}
                        name={user.name}
                        callback={this.isCannedOrNot}
                      />,
                    ]}
                    onEditorStateChange={this.onEditorStateChange}
                  />
                </Col>
              </FormGroup>
              <div className="Retailer-ContactForm__footer form-group">
                <div className="Retailer-ContactForm__footer__leftcol">
                  <FileUpload callback={this.uploadedFiles} />
                </div>
                <div className="Retailer-ContactForm__footer__rightcol">
                  <button
                    type="submit"
                    className="btn btn-default Retailer-ContactForm__footer__rightcol-btn"
                  >
                    SEND
                  </button>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
        {showSuccessModal && (
          <SuccessModal
            show={showSuccessModal}
            mode="contact"
            callback={this.props.callback}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contactInfo: state.contactInfo,
    retailer: state.retailer,
    products: state.products,
    product: state.product,
  };
};

export default withRouter(connect(mapStateToProps, null)(RetailerContactForm));
