import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Nav,
  NavItem,
  Tab,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import _ from 'lodash';
import Card from 'dashboard/components/Card/Card.jsx';
import RetailerLastScrapeProductsTable from 'dashboard/components/Tables/RetailerLastScrapeProductsTable.jsx';
import RetailerProductsTable from 'dashboard/components/Tables/RetailerProductsTable.jsx';
import RetailerFlaggedProductsTable from 'dashboard/components/Tables/RetailerFlaggedProductsTable.jsx';
import thunkFetchProducts from 'dashboard/thunks/thunkFetchProducts';
import moment from 'moment';
import RetailerProductsPriceIssueTable from '../components/Tables/RetailerProductsPriceIssueTable';
import RetailerProductBasket from '../components/Tables/RetailerProductBasket';
import { toastr } from 'react-redux-toastr';

class RetailerTab extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleExportData = this.handleExportData.bind(this);
    this.handleBasketProducts = this.handleBasketProducts.bind(this);

    this.state = {
      // eventKey: "changes",
      eventKey: 'price_issues',
      exportData: '',
      basketItems: []
    };
  }

  componentDidMount() {
    // Default call
    // console.log('from reatiler');

    // this.props.doFetchProducts({
    //   tab: "change",
    //   page: 1,
    // });
    // this.props.doFetchProducts("price_issues");
    this.handleSelect('price_issues');
  }

  handleBasketProducts(value, type) {
    console.log('value :', value, '\ntype :', type);
    if (value && type === 'Add') {
      this.setState(prevState => ({
        basketItems: [...prevState.basketItems, value]
      }));
      toastr.success('SUCCESS', 'Product Added.');
    }
    if (value && type === 'Delete') {
      this.setState(prevState => ({
        basketItems: prevState.basketItems.filter(
          item => item._id !== value._id
        )
      }));
      toastr.success('SUCCESS', 'Product Removed.');
    }
  }

  handleExportData(value) {
    this.setState({ exportData: value });
  }

  handleSelect(eventKey) {
    // const rId = this.props.retailerScrappingInfo._id;
    let atts = {
      page: 1
    };
    switch (eventKey) {
      case 'changes':
        atts = _.extend(atts, {
          tab: 'change'
        });
        this.handleExportData('');
        break;
      case 'flagged':
        atts = _.extend(atts, {
          isFlagged: 'yes'
        });
        this.handleExportData('');
        break;
      case 'products':
        this.handleExportData('all');
        break;
      case 'price_issues':
        atts = 'price_diff';
        this.handleExportData('');
        break;
      case 'all_oh':
        atts = 'all_oh';
        this.handleExportData('');
        break;
      case 'not_oh':
        atts = 'sd_data';
        this.handleExportData('');
        break;
      case 'oh':
        atts = 'oh_data';
        this.handleExportData('');
        break;
      case 'in_price_sync':
        atts = 'price_match';
        this.handleExportData('');
        break;
      case 'oh_scraped':
        atts = 'oh_sd_data';
        this.handleExportData('');
        break;
      default:
    }

    this.setState(
      {
        eventKey
      },
      () => {
        // console.log({ atts });
        if (eventKey !== 'basket') {
          this.props.doFetchProducts(atts);
        }
      }
    );
  }

  render() {
    let { retailerScrappingInfo } = this.props;
    let { previousPeriod } = retailerScrappingInfo;
    if (_.isEmpty(retailerScrappingInfo)) {
      return <div />;
    }

    const { eventKey } = this.state;

    const tabs = (
      <Tab.Container
        id="tabs-with-dropdown"
        activeKey={eventKey}
        onSelect={this.handleSelect}
      >
        <Row className="clearfix">
          <Col sm={12}>
            <Nav bsStyle="tabs">
              <NavItem eventKey="price_issues">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      Items scraped and mapped that are not the correct price.
                    </Tooltip>
                  }
                >
                  <h4 className="active-tab">Price Issues</h4>
                </OverlayTrigger>
              </NavItem>
              <NavItem eventKey="flagged">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      Items that have previously been flagged for attention.
                    </Tooltip>
                  }
                >
                  <h4 className="active-tab">Flagged Products</h4>
                </OverlayTrigger>
              </NavItem>
              <NavItem eventKey="changes">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      Items on site that have been add/changed/removed since
                      last scan.
                    </Tooltip>
                  }
                >
                  <>
                    <h4>Changes Since last scan</h4>
                    {previousPeriod ? (
                      <p className="last-scan-date text-center">
                        (Compared to{' '}
                        {moment(new Date(`${previousPeriod}`)).format(
                          'MM/DD/YY'
                        )}
                        )
                      </p>
                    ) : (
                      <p className="last-scan-date text-center"> (N/A)</p>
                    )}
                  </>
                </OverlayTrigger>
              </NavItem>

              <NavItem eventKey="all_oh">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      All items Acumatica believes are on hand.
                    </Tooltip>
                  }
                >
                  <h4 className="active-tab">All OH</h4>
                </OverlayTrigger>
              </NavItem>

              <NavItem eventKey="oh">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      On hand items that have not been mapped to scraped data.
                    </Tooltip>
                  }
                >
                  <h4 className="active-tab">OH (NOT Scraped)</h4>
                </OverlayTrigger>
              </NavItem>
              <NavItem eventKey="oh_scraped">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      placement="top"
                      className="in"
                      id="tooltip-top"
                    ></Tooltip>
                  }
                >
                  <h4 className="active-tab">OH (Scraped)</h4>
                </OverlayTrigger>
              </NavItem>
              <NavItem eventKey="products">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      All items scraped from website.
                    </Tooltip>
                  }
                >
                  <h4 className="active-tab">ALL SCRAPED</h4>
                </OverlayTrigger>
              </NavItem>
              <NavItem eventKey="not_oh">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      Items scraped that aren't mapped to any on hand data.
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}
                >
                  <h4 className="active-tab">Scraped (NOT OH)</h4>
                </OverlayTrigger>
              </NavItem>
              <NavItem eventKey="in_price_sync">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      Items that are in sync with Acumatica pricing.
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}
                >
                  <h4 className="active-tab">In Price Sync</h4>
                </OverlayTrigger>
              </NavItem>

              <NavItem eventKey="basket">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      Create a basket of items from any combnination of the tabs
                      and export and share with retailer.
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}
                >
                  <h4 className="active-tab">BASKET</h4>
                </OverlayTrigger>
              </NavItem>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content animation={false}>
              {' '}
              <Tab.Pane eventKey="price_issues">
                <RetailerProductsPriceIssueTable {...this.props} />
              </Tab.Pane>
              <Tab.Pane eventKey="flagged">
                <RetailerFlaggedProductsTable {...this.props} />
              </Tab.Pane>
              <Tab.Pane eventKey="changes">
                <RetailerLastScrapeProductsTable {...this.props} />
              </Tab.Pane>
              <Tab.Pane eventKey="all_oh">
                <RetailerProductsTable
                  {...this.props}
                  tabName="all_oh"
                  subject="Kwiat Completed On Hand Data Feed"
                  handleBasketProducts={this.handleBasketProducts}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="oh">
                <RetailerProductsTable
                  {...this.props}
                  tabName="oh"
                  subject="Kwiat On Hand Data Feed - Missing from website"
                  handleBasketProducts={this.handleBasketProducts}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="oh_scraped">
                <RetailerProductsTable
                  {...this.props}
                  tabName="oh_scraped"
                  subject="Kwiat On Hand Data Feed - Currently on Website"
                  handleBasketProducts={this.handleBasketProducts}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="products">
                <RetailerProductsTable
                  {...this.props}
                  tabName="products"
                  filter={true}
                  handleExportData={this.handleExportData}
                  exportData={this.state.exportData}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="not_oh">
                <RetailerProductsTable {...this.props} />
              </Tab.Pane>
              <Tab.Pane eventKey="in_price_sync">
                <RetailerProductsTable {...this.props} />
              </Tab.Pane>
              <Tab.Pane eventKey="basket">
                <RetailerProductBasket
                  {...this.props}
                  tabName="all_oh"
                  basketItems={this.state.basketItems}
                  handleBasketProducts={this.handleBasketProducts}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );

    return (
      <div className="retailer-tab">
        <Row>
          <Col md={12}>
            <Card ctFullWidth content={tabs} />
          </Col>
        </Row>
      </div>
    );
  }
}

/* const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      doFetchProducts
    },
    dispatch
  );
}; */

const mapDispatchToProps = dispatch => {
  return {
    doFetchProducts: value => {
      // console.log(value, "value");
      dispatch(thunkFetchProducts({ atts: value }));
    }
  };
};

export default connect(null, mapDispatchToProps)(RetailerTab);
