import { history } from '../configureStore';
import { omni_axios_instance } from 'utils/axios';
import store from 'store';
import ReactGA from 'react-ga';
import isEmpty from 'lodash/isEmpty';
const __AUTH_KEY__ = 'omni_auth';

let _getAuthValueFromStore = (key = __AUTH_KEY__) => {
  return JSON.parse(localStorage.getItem(key)) || {};
};

let _setAuthValueInStore = (value, key = __AUTH_KEY__) => {
  value = value || {};
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    throw new Error(e);
  }
  return _getAuthValueFromStore();
};

let _removeAuthValueInStore = (key = __AUTH_KEY__) => {
  let uAuth = _getAuthValueFromStore();
  try {
    localStorage.removeItem(key);
  } catch (e) {
    throw new Error(e);
  }
  return uAuth;
};

class Auth {
  constructor(name) {
    this.getUserRoles = this.getUserRoles.bind(this);
    this.adminAsBrandUser = false;
    // this.admin = this.getUserRoles().includes("ADMIN");
    // return roles.
  }

  // Sets user details in localStorage
  saveAuth(authResult) {
    return new Promise(function(resolve, reject) {
      let val = _setAuthValueInStore(authResult);
      if (val) {
        resolve(val);
      } else {
        reject(`Error: can't save session.`);
      }
    });
  }

  // Sets user details in localStorage
  removeAuth() {
    return new Promise(function(resolve, reject) {
      let uAuth = _removeAuthValueInStore();
      let user = uAuth.user || {};
      if (!isEmpty(user)) {
        ReactGA.event({
          category: 'authentication',
          action: 'logged out',
          label: user.email
        });
        resolve(uAuth);
      } else {
        reject(`Error: can't remove session.`);
      }
    });
  }

  // checks if the user is authenticated
  getAccessToken() {
    let uAuth = _getAuthValueFromStore();
    return uAuth.token || '';
  }
  setAdminAsBrandUser(brand) {
    let uAuth = _getAuthValueFromStore();
    Object.assign(uAuth.user, { brand });
    _setAuthValueInStore(uAuth);
    this.adminAsBrandUser = true;
  }

  // get logged in user
  getUser() {
    let uAuth = _getAuthValueFromStore();
    return uAuth.user || {};
  }

  // roles array
  getUserRoles() {
    let uAuth = _getAuthValueFromStore();
    if (isEmpty(uAuth)) {
      return [];
    }
    let { roles } = uAuth.user;
    roles = roles || [];
    roles = roles.map(role => role.name);
    return roles;
  }
  isSiteAdmin() {
    // return true;
    let roles = this.getUserRoles();
    console.log('isAdmin', roles.includes('ADMIN'));
    return roles.includes('ADMIN');
  }
  // is admin
  isAdmin() {
    // return false;
    // return this.admin;
    if (this.adminAsBrandUser) return false;
    let roles = this.getUserRoles();
    return roles.includes('ADMIN');
  }

  isBrandAdmin() {
    let roles = this.getUserRoles();
    return roles.includes('BRAND_ADMIN');
  }

  loginRedirect() {
    let omni_deeplink = store.get('omni_deeplink');
    // If User coming from any other third party app/website
    // Then redirct to that particular page.
    let isComp = omni_deeplink && omni_deeplink.path.includes('comparison');

    let isAdmin = this.isAdmin();
    if (isComp && isAdmin) {
      let url = `${omni_deeplink.path}${omni_deeplink.qs}`;
      // Remove deeplinking data from store.
      store.remove('omni_deeplink');
      history.push(url);
      return;
    }

    if (isAdmin) {
      // history.push('/admin');
      history.push('/admin/confirm');
    } else {
      // Otherwise redirect to summary page after login.
      let user = this.getUser();
      ReactGA.ga('set', 'userId', user.email);
      ReactGA.ga('set', 'dimension1', user.brand.slug);
      // Setting the userId doesn't send data to Google Analytics.
      // You must also use a pageview or event to send the data.
      ReactGA.event({
        category: 'authentication',
        action: 'logged in',
        label: user.email
      });
      // Otherwise redirect to summary page after login.
      if (user.policyFlag === undefined) {
        history.push('/dashboard/summary');
      }
      if (user.policyFlag === true) {
        history.push('/privacypolicy');
      } else {
        history.push('/dashboard/summary');
      }
    }
  }
  // Login User
  login(userCrenetials, props) {
    omni_axios_instance
      .post('/login', userCrenetials)
      .then(response => {
        this.saveAuth(response.data).then(message => {
          this.loginRedirect();
        });
      })
      .catch(error => {
        console.log('props', props);
        let failedRedirect = props
          ? props.failedRedirect
          : '/login?login=failed';
        history.push(failedRedirect);
      });
  }
}

let auth = new Auth();

export default auth;
