import _ from 'lodash';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';
import { omni_axios_instance } from 'utils/axios';
import { toastr } from 'react-redux-toastr';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import BrandSku from '../dashboard/components/catalog-matching/BrandSku';

// Price display options
const CURRENCY_SEPERATOR = ',';
const CURRENCY = '$';

const Helpers = {
  scrollToTop: function() {
    scroll.scrollToTop();
  },
  editBrandSku: function(e) {
    let showEditBrandModal = true;
    // console.log('showEditBrandModal', showEditBrandModal);
    return showEditBrandModal;
  },
  getDrillDown: function(product, retailerName, isModal, lastScan) {
    let timeZone = new Date().getTimezoneOffset();
    // console.log(timeZone,'timeZone')
    let brandSku =
      (product.product && product.product.sku) || product.brand_sku || `N/A`;
    let drillDown = {
      'Last Scan (current period)': lastScan
        ? moment(new Date(`${lastScan}`))
            .add(timeZone, 'minutes')
            .format('MMMM DD, YYYY')
        : 'N/A',
      'Retailer Name': retailerName,
      'Product Name': product.name,
      Price: this.setProductPrice(product.price),
      Shoppable: product.shoppability,
      Details: 'N/A',
      'Brand SKU': brandSku,
      'Retailer SKU': product.style_number, // retailer.retailer_sku,
      // Reviews: product.review,
      Description: product.product_description
    };

    const drillDownKeys = Object.keys(drillDown);
    let content = drillDownKeys.map((value, key) => {
      let displayValue = '';
      let displayValId = value.replace(/\s/g, '');
      if (value === 'Retailer Name' && isModal) {
        displayValue = (
          <Link
            to={`/dashboard/retailer/${product.retailer_brand_scraping}/product/${product._id}`}
          >
            {' '}
            {drillDown[value]}
          </Link>
        );
      } else if (value === 'Brand SKU' && drillDown[value] === 'N/A') {
        displayValue = <BrandSku />;
      } else {
        // for removing whitespace .replace(/\s+/g,' ')
        // for removing extra line .replace(/^\s*[\r\n]/gm, '')
        // console.log(' drillDown[value]',  value, drillDown[value]);
        displayValue = (
          <div
            className="preformatted-text"
            dangerouslySetInnerHTML={{ __html: drillDown[value] }}
          />
        );
      }
      return (
        <div key={key} className="drill-props">
          <Row>
            <Col xs={4} id={displayValId} className="property-name">
              {value}:
            </Col>
            <Col xs={8}>{displayValue}</Col>
          </Row>
        </div>
      );
    });

    return content;
  },
  getNextScan: function(list, monthId, nextScanDay) {
    // console.log('nextScanDay',nextScanDay);
    let get_index = _.findIndex(list, function(monthval) {
      return monthval._id === monthId;
    });
    if (get_index === -1) {
      return;
    }
    let index = get_index === 0 ? get_index : get_index - 1;
    let monthCount;
    if (list[index]._id === '' || get_index === 0) {
      let now = new Date();
      let year;
      if (now.getMonth() === 11 && nextScanDay <= now.getDate()) {
        year = now.getFullYear() + 1;
        monthCount = 1;
      } else {
        monthCount = 0;
        year = now.getFullYear();
      }
      let current = new Date(year, now.getMonth(), nextScanDay && nextScanDay);
      return (
        nextScanDay &&
        `${moment(current)
          .add(monthCount, 'M')
          .format('MMMM')} ${
          nextScanDay > 9 ? '' + nextScanDay : '0' + nextScanDay
        }, ${moment(current).format('YYYY')}`
      );
    } else {
      return moment(new Date(list[index].value)).format('MMMM DD, YYYY');
    }
  },

  getPreviousScan: function(list, monthId) {
    var timeZone = new Date().getTimezoneOffset();
    var get_index = _.findIndex(list, function(monthval) {
      return monthval._id === monthId;
    });
    var last_index = list.length - 1;
    var index = get_index === last_index ? get_index : get_index + 1;

    if (list[index]._id === '' || get_index === last_index) {
      return 'N/A';
    } else {
      return moment(new Date(list[index].value))
        .add(timeZone, 'minutes')
        .format('MMMM DD, YYYY');
    }
  },
  getSocialStats: function(data) {
    let twitter = [];
    let facebook = [];
    let instagram = [];
    let pinterest = [];
    let series = [];
    _.forEach(data, function(prop, key) {
      switch (prop.name) {
        case 'Twitter':
          twitter = prop.value;
          break;
        case 'Facebook':
          facebook = prop.value;
          break;
        case 'Pinterest':
          pinterest = prop.value;
          break;
        case 'Instagram':
          instagram = prop.value;
          break;
        default:
          break;
      }
    });
    series.push(twitter, facebook, instagram, pinterest);

    let omniSales = {
      labels: ['1/01', '1/05', '1/10', '1/15', '1/20', '1/25', '1/30'],
      series
    };
    return omniSales;
  },

  checkRetailerModule: obj => {
    let tooltipText, img;
    if (obj.isModule) {
      if (obj.abcWithProduct) {
        tooltipText = `This Retailer displays products and uses a Module to display ${obj.brandName} items on their website.`;
      } else {
        tooltipText = `This Retailer uses a Module to display ${obj.brandName} items on their website.`;
      }
      img = `abc_icon_${obj.imgType}.png`;
    } else {
      tooltipText =
        'This Retailer does not feature any product pages or a module on their website';
      img = `No_product_icon_${obj.imgType}.png`;
    }

    return (
      <>
        <span data-tip={tooltipText}>
          <img className="module-icon" src={`module-icons/${img}`} alt="" />
        </span>
        <ReactTooltip
          className="omni__tooltip omni__tooltip--right"
          place="right"
          type="light"
          effect="solid"
        />
      </>
    );
  },

  setFlaggedProduct: async function(formData, pId) {
    let msg = '';
    if (formData.isFlagged === 'yes') {
      msg = 'Product flagged successfully.';
    } else {
      msg = 'Product Unflagged successfully.';
    }
    return await omni_axios_instance
      .put(`/updateReatilerBrandProductFlag/${pId}`, formData)
      .then(res => {
        toastr.success('SUCCESS', msg);
      });
  },

  setBrandFlag: function(formData, retailerBrandScrappingid) {
    let msg = '';
    if (formData.isFlagged === 'yes') {
      msg = 'Brand flagged successfully.';
    } else {
      msg = 'Brand Unflagged successfully.';
    }
    return omni_axios_instance
      .put(`/updateBrandFlag/${retailerBrandScrappingid}`, formData)
      .then(res => {
        toastr.success('SUCCESS', msg);
      });
  },

  getFormattedCurrency: function(number) {
    if (!number) {
      return;
    }
    number = _.toString(number);

    number = number.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      '$1' + CURRENCY_SEPERATOR
    );
    number = `${CURRENCY}${number}`;
    return number;
  },

  formatCurrency: function(number) {
    if (!number) {
      return null;
    }

    return (
      <NumberFormat
        value={number}
        displayType={'text'}
        thousandSeparator={CURRENCY_SEPERATOR}
        prefix={CURRENCY}
      />
    );
  },

  setProductPrice: function(price) {
    if (!price) {
      return 'N/A';
    } else {
      return this.getFormattedCurrency(price);
    }
  },

  getFlaggedReason: function(product) {
    let { reason, message } = product.flagMessage || {};
    reason = reason || [];
    message = message || '';

    let additionalDetails = reason
      .map((item, key) => {
        if (item.value) {
          return `${_.capitalize(item.name)} : ${item.value}`;
        }
        return `${_.capitalize(item.name)}`;
      })
      .join('<br />');

    additionalDetails += `<br /> ${message}`;
    return additionalDetails;
  }
};

export default Helpers;
