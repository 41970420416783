import React from 'react';
import WhyToFlagModal from 'dashboard/components/WhyToFlagModal';
import Helpers from 'utils/helpers';
import confirm from 'dashboard/components/confirm';
import thunkFetchProducts from 'dashboard/thunks/thunkFetchProducts';
import { connect } from 'react-redux';

class OmniSwitch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showWhyToFlagModal: false,
      isFlagged: this.props.isFlagged || ''
    };
    this.toggleFlagProduct = this.toggleFlagProduct.bind(this);
    this.onWhyToFlagModalClose = this.onWhyToFlagModalClose.bind(this);
  }

  toggleFlagProduct() {
    let { id: pId, tab } = this.props;
    let { isFlagged } = this.state;
    // Previously it was flagged
    if (isFlagged) {
      confirm('Are you sure you want to un flag this product ?').then(
        result => {
          // `proceed` callback
          Helpers.setFlaggedProduct({ isFlagged: 'no' }, pId);
          this.setState(
            {
              isFlagged: false
            },
            () => {
              if (tab && tab === 'price_issue') {
                setTimeout(() => {
                  this.props.doFetchProducts('price_diff');
                }, 200);
              } else {
                setTimeout(() => {
                  this.props.doFetchProducts({
                    // IS_REFRESH_FLAG: true,
                    ...this.props.filters
                  });
                }, 200);
              }
            }
          );

          this.props.callback({
            isFlagged: false,
            reason: []
          });
          console.log('proceed called');
        },
        result => {
          // `cancel` callback
        }
      );
    } else {
      // otherwise show modal
      this.setState({
        showWhyToFlagModal: true
      });
    }
  }

  onWhyToFlagModalClose(params) {
    let { tab } = this.props;
    this.setState(
      {
        showWhyToFlagModal: false,
        isFlagged: params ? params.isFlagged : ''
      },
      () => {
        if (tab && tab === 'price_issue') {
          this.props.doFetchProducts('price_diff');
        } else {
          this.props.doFetchProducts({
            // IS_REFRESH_FLAG: true,
            ...this.props.filters
          });
        }
      }
    );
    this.props.callback(params);
  }

  render() {
    let { showWhyToFlagModal, isFlagged } = this.state;
    let { rId, id: pId, type, productName } = this.props;

    let classes = '';
    if (isFlagged) {
      classes = 'fa fa-flag flag-marked fa-lg';
    } else {
      classes = 'fa fa-flag flag-unmarked fa-lg';
    }

    if (type === 'deleted') {
      return <div />;
    }

    return (
      <div className="OmniSwitch-Component">
        <div className="OmniSwitch-Item" onClick={this.toggleFlagProduct}>
          <i className={classes} />
        </div>
        {showWhyToFlagModal && (
          <WhyToFlagModal
            rId={rId}
            pId={pId}
            productName={productName}
            callback={this.onWhyToFlagModalClose}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { filters: state.products.filters };
};

const mapDispatchToProps = dispatch => {
  return {
    doFetchProducts: value => {
      dispatch(thunkFetchProducts({ atts: value }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OmniSwitch);
